export default {
    data() {
        return {
            // 登录
            usernamErr:'',
            usernameKey:true,
            passwordErr:'',
            passwordKey:true,

            // 注册
            r_usernamErr:'',
            r_usernameKey:true,
            r_passwordErr:'',
            r_passwordKey:true,
            r_password_again_Err:'',
            r_password_aganin_Key:true,
            r_email_Err:'',
            r_email_Key:true
            
        }
    },
    methods: {
        //登录
        check_username(){
            // 另一种方法 {target:{_value:value}}
           let test = /^[\u4e00-\u9fa5a-zA-Z0-9]{2,10}$/
           if(test.test(this.login_form.username)){
                this.usernameKey = true
           }else{
                this.usernamErr = '请输入2-10位的中英文'
                this.usernameKey = false
           }
        },

        check_password(){
            let test = /^[a-zA-Z0-9]{8,16}$/
            if(test.test(this.login_form.password)){
                 this.passwordKey = true
            }else{
                 this.passwordErr = '请输入8-16位密码'
                 this.passwordKey = false
            }
        },

        //注册
       async r_check_username(){
            console.log('ok');
           let test = /^[\u4e00-\u9fa5a-zA-Z0-9]{2,10}$/
           if(test.test(this.register_form.username)){
                // 校验重复
                const {data:res} = await this.$http.post('check_username/',{username:this.register_form.username})
                if(res.code!=='200'){
                    this.r_usernamErr = '输入失败 请重新加载'
                    this.r_usernameKey = false
                }else{
                    if(res.data==1){
                         this.r_usernamErr = '用户已存在'
                         this.r_usernameKey = false
                    }else if(res.data==0){
                         this.r_usernameKey = true
                    }else{
                         this.r_usernamErr = '输入失败 请重新加载'
                         this.r_usernameKey = false
                    }
                }
           }else{
                this.r_usernamErr = '请输入2-10位的中英文'
                this.r_usernameKey = false
           }
        },

        r_check_password(){
            let test = /^[a-zA-Z0-9]{8,16}$/
            if(test.test(this.register_form.password)){
                 this.r_passwordKey = true
            }else{
                 this.r_passwordErr = '请输入8-16位密码'
                 this.r_passwordKey = false
            }
        },
        r_check_password_again(){
            if(this.register_form.password == this.register_form.password_again){
                 this.r_password_aganin_Key = true
            }else{
                 this.r_password_again_Err = '输入密码不一致'
                 this.r_password_aganin_Key = false
            }
        },


       async r_check_email(){
            let test = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
            if(test.test(this.register_form.email)){
                 // 校验重复
                  const {data:res} = await this.$http.post('check_email/',{email:this.register_form.email})
                  if(res.code!=='200'){
                    this.r_email_Err = '输入失败 请重新加载'
                    this.r_email_Key = false
                  }else{
                       if(res.data==1){
                         this.r_email_Err = '邮箱已存在'
                         this.r_email_Key = false
                       }else if(res.data==0){
                         this.r_email_Key = true
                       }else{
                         this.r_email_Err = '输入失败 请重新加载'
                         this.r_email_Key = false
                       }
                  }
                  
            }else{
                 this.r_email_Err = '请输入正确的邮箱'
                 this.r_email_Key = false
            }
        }

      
        
    },
}