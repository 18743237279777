<template>
  <div class="login">
    <div class="login-form">
      <div
        :class="{
          'change-img': true,
          'move-right ': moveDire === 'r',
          'move-left': moveDire === 'l',
        }"
      >
        <img
          :class="
            moveDire === 'r'
              ? 'img-opacity'
              : moveDire === 'l'
              ? 'img-opacity-hf'
              : ''
          "
          style="z-index: 99"
          src="http://120.24.182.152:5555/s/static/s/image_files/users_image/login1.png"
          alt=""
        />
        <img
          src="http://120.24.182.152:5555/s/static/s/image_files/users_image/login2.png"
          alt=""
        />
        <div
          :class="{
            'change-login': true,
            but_to_login: moveDire === 'r',
            but_to_register: moveDire === 'l',
          }"
          style="z-index: 102"
          @click="changeMove"
        >
          去登录
        </div>
      </div>
      <div class="left-form-wrap">
        <div class="form-main">
          <div class="title">
            <h2>注册</h2>
          </div>
          <div class="row">
            <input
              type="text"
              v-model="register_form.username"
              @blur="r_check_username"
              placeholder="请输入名称"
            />
          </div>
          <div class="warning">
            <span v-show="!r_usernameKey">{{ r_usernamErr }}</span>
          </div>
          <div class="row">
            <input
              type="password"
              v-model="register_form.password"
              @blur="r_check_password"
              placeholder="请输入密码"
            />
          </div>
          <div class="warning">
            <span v-show="!r_passwordKey">{{ r_passwordErr }}</span>
          </div>
          <div class="row">
            <input
              type="password"
              v-model="register_form.password_again"
              @blur="r_check_password_again"
              placeholder="请再次输入密码"
            />
          </div>
          <div class="warning">
            <span v-show="!r_password_aganin_Key">{{
              r_password_again_Err
            }}</span>
          </div>
          <div class="row">
            <input
              type="text"
              v-model="register_form.email"
              @blur="r_check_email"
              placeholder="请输入邮箱"
            />
          </div>
          <div class="warning">
            <span v-show="!r_email_Key">{{ r_email_Err }}</span>
          </div>

          <div class="row">
            <div class="login_but" @click="submintRegister">注册</div>
          </div>
        </div>
      </div>
      <div class="rigth-form-wrap">
        <div class="form-main">
          <div class="title">
            <h2>登录</h2>
          </div>
          <div class="row">
            <input
              type="text"
              v-model="login_form.username"
              @blur="check_username"
              placeholder="请输入名称或邮箱"
            />
          </div>
          <div class="warning">
            <span v-show="!usernameKey">{{ usernamErr }}</span>
          </div>
          <div class="row">
            <input
              type="password"
              v-model="login_form.password"
              @blur="check_password"
              placeholder="请输入密码"
            />
          </div>
          <div class="warning">
            <span v-show="!passwordKey">{{ passwordErr }}</span>
          </div>
          <div class="row">
            <div class="login_but" @click="submitLogin">登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkForm from "../comment/check-form";
import myStore from "../utils/localStore";

export default {
  name: "login",
  mixins: [checkForm],
  data() {
    return {
      // csrf_token: "",
      login_form: {
        username: "",
        password: "",
      },
      register_form: {
        username: "",
        password: "",
        password_again: "",
        email: "",
      },
      moveDire: "conter", // 移动方向
    };
  },
  mounted() {},
  methods: {
    // 登录
    async submitLogin() {
      this.check_username();
      this.check_password();
      if (this.usernameKey && this.passwordKey) {
        const { data: res } = await this.$http.post("login/", this.login_form);
        if (res.code !== "200") return;
        //  this.$store.dispatch('user/save_user_info',res.data)
        myStore.setStore("userinfo", res.data);
        myStore.setStore('token', res.data.token)
        this.$router.replace("/");
      }
    },
    // 注册
    async submintRegister() {
      this.r_check_username();
      this.r_check_password();
      this.r_check_password_again();
      this.r_check_email();
      if (
        this.r_usernameKey &&
        this.r_passwordKey &&
        this.r_password_aganin_Key &&
        this.r_email_Key
      ) {
        const { data: res } = await this.$http.post(
          "register/",
          this.register_form
        );
        if (res.code !== "200") return;
        // this.$store.dispatch('user/save_user_info',res.data)
        myStore.setStore("userinfo", res.data);
        myStore.setStore('token', res.data.token)
        this.$router.replace("/");
      }
    },
    goBackView() {
      this.$router.go(-1);
    },
    changeMove() {
      if (this.moveDire === "l" || this.moveDire === "conter") {
        this.moveDire = "r";
      } else {
        this.moveDire = "l";
      }
      console.log(this.$refs.change);
    },
  },
};
</script>

<style lang='less' scoped>
.login {
  width: 100%;
  height: calc(100% - 60px);
  background: #f6f5f8;

  .login-form {
    position: relative;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    padding: 2% 1%;
    background: #fff;

    .change-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: #279ca3;

      .change-login {
        position: absolute;
        top: 10px;
        // transform: translateX(20px);
        width: 60px;
        height: 30px;
        font-size: 16px;
        color: #fff;
        line-height: 30px;
        text-align: center;
        background: #3d5aeb;
        border-radius: 10px;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    // 注册
    .left-form-wrap {
      float: left;
      width: 50%;
      height: 100%;

      .form-main {
        margin-top: 25%;
        .title {
          text-align: center;
          h2 {
            margin: 0;
            margin-bottom: 50px;
          }
        }

        .row {
          width: 100%;
          text-align: center;
          padding: 0 5px;
          input {
            width: 70%;
            height: 40px;
            font-size: 20px;
            padding-left: 10px;
            caret-color: #3d5aeb; // 修改 焦点光标
            outline-color: #3d5aeb; // 选中后框的颜色
            background: #f6f5f8;
            border: 1px solid rgb(201, 200, 200);
          }
          .login_but {
            width: 50%;
            height: 40px;
            background: #3d5aeb;
            margin-left: 25%;
            color: #fff;
            font-size: 20px;
            line-height: 40px;
            border-radius: 5px;

            box-shadow: 2px 2px 1px rgb(136, 136, 136);
          }
          //   修改placeholder
          input::-webkit-input-placeholder {
            color: rgb(143, 143, 143);
            font-size: 16px;
          }
        }

        // 警告
        .warning {
          height: 30px;
          margin-left: 15%;
          width: 300px;
          padding: 0 10px;
          color: #3d5aeb;
          font-size: 14px;
          line-height: 30px;
        }
      }
    }

    .rigth-form-wrap {
      float: right;
      width: 50%;
      height: 100%;

      .form-main {
        margin-top: 25%;
        .title {
          text-align: center;
          h2 {
            margin: 0;
            margin-bottom: 50px;
          }
        }

        .row {
          width: 100%;
          text-align: center;
          padding: 0 5px;
          input {
            width: 70%;
            height: 40px;
            font-size: 20px;
            padding-left: 10px;
            caret-color: #3d5aeb; // 修改 焦点光标
            outline-color: #3d5aeb; // 选中后框的颜色
            background: #f6f5f8;
            border: 1px solid rgb(201, 200, 200);
          }
          .login_but {
            width: 50%;
            height: 40px;
            background: #3d5aeb;
            margin-left: 25%;
            color: #fff;
            font-size: 20px;
            line-height: 40px;
            border-radius: 5px;

            box-shadow: 2px 2px 1px rgb(136, 136, 136);
          }
          //   修改placeholder
          input::-webkit-input-placeholder {
            color: rgb(143, 143, 143);
            font-size: 16px;
          }
        }

        // 警告
        .warning {
          height: 30px;
          margin-left: 15%;
          width: 300px;
          padding: 0 10px;
          color: #3d5aeb;
          font-size: 14px;
          line-height: 30px;
        }
      }
    }
  }
}

// elemet 样式修改
.el-input {
  background: #f6f5f8 !important;
  z-index: 100 !important;
}

//动画
.move-right {
  animation: move_one 0.5s linear;
  transform: translateX(100%);
}
.move-left {
  animation: move_one_hf 0.5s linear;
  transform: translateX(0);
}

// 移动图片
@keyframes move_one {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

// 图片透明
.img-opacity {
  animation: imgChange 0.5s linear;
  opacity: 0;
}
.img-opacity-hf {
  animation: imgChangehf 0.5s linear;
  opacity: 1;
}

@keyframes imgChange {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes imgChangehf {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes move_one_hf {
  0% {
    transform: translateX(100%);
  }
  50% {
  }
  100% {
    transform: translateX(0);
  }
}

.but_to_login {
  animation: move_two 0.5s;
  left: 85%;
}
.but_to_register {
  animation: move_two_fy 0.5s;
  left: 4%;
}

// 移动按钮
@keyframes move_two {
  0% {
    left: 4%;
  }
  100% {
    left: 85%;
  }
}

@keyframes move_two_fy {
  0% {
    left: 85%;
  }
  100% {
    left: 4%;
  }
}
</style>